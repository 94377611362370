
export default {
  head: {
    link: [
      {
        rel: 'preload',
        as: 'style',
        href: 'https://cookies.metterdaad.nl/sites/EO/metterdaad.nl/npo-lowerbar_v3.1.14.css',
        crossorigin: '',
      },
    ],
  },
  methods: {
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
