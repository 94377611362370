import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('atInternet', {
      atSiteId: 'siteId',
      atUserId: 'userId',
      atUserIdDuration: 'userIdExpiresInDays',
    }),
    ...mapGetters('cookieConsent', {
      cookiePermissions: 'permissions',
    }),
  },
}
