
import { metaDataMixin } from '~/mixins'

export default {
  name: 'ErrorPage',
  mixins: [metaDataMixin],
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      keyword: null,
      seo: {
        title: this.seoTitle,
      },
    }
  },
  computed: {
    intro () {
      return 'Sommige informatie is helaas niet meer beschikbaar vanwege onze nieuwe website.'
    },
    message () {
      if (this.error?.message) {
        return this.error.message
      }
      if (this.statusCode === 404) {
        return 'De pagina is niet gevonden'
      }
      return 'Een onbekende fout heeft zich voorgedaan'
    },
    statusCode () {
      return this.error?.statusCode || 500
    },
    title () {
      if (this.error?.message) {
        return this.error.message
      }

      return 'Oeps! Er is iets mis gegaan...'
    },
    seoTitle () {
      if (this.statusCode === 404) {
        return 'Pagina niet gevonden'
      }
      return 'Er is een fout opgetreden'
    },
  },
  mounted () {
    // Use mounted() to report bugs in client, when client is ready.
    // Used for reporting to bugsnag
    const referrer = process.client && document?.referrer
    const {
      context,
      message = 'Oeps! Er is iets mis gegaan...',
      path = referrer,
      severity,
      statusCode = 500,
    } = this.error

    // Temporary disable 404 reports to Bugsnag for preventing "sampling" in bugsnag
    if (statusCode !== 404) {
      this.$bugsnag.notify(new Error(`${statusCode}: ${message}`), (e) => {
        e.severity = severity || (statusCode === 404 ? 'info' : 'error')
        e.context = context || path
        e.addMetadata('Error info', {
          ...this.error,
          referrer,
        })
      })
    }
  },
}
