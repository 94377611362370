const axios = require('axios')

const apiHost = process.env.NEW_EO_API_HOST || 'https://api.eo.nl'

export default async function ({ redirect, route }) {
  const { fullPath, path, query } = route
  if (path !== '/publication' && path !== '/publication/') {
    return
  }

  const errorPage = `/error${fullPath}`

  const {
    id,
    'model-id': modelId,
  } = query

  let { slug } = query

  const pageRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`${apiHost}/pages/${id}`)
      slug = pubSlug
    }
    return redirect(301, `/${slug}`)
  }

  if (modelId) {
    switch (modelId) {
      case 'eceb6d16-a6e8-439c-88d8-286c97a580ff': // page
        return await pageRoute()
    }
  }

  if (slug) {
    return redirect(301, slug)
  }

  redirect(errorPage)
}
